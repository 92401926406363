export enum CouponStatus {
  SCHEDULING = 'scheduling',
  PUBLISHED = 'published',
  RUN_OUT = 'run_out',
  EXPIRED = 'expired',
  TAKEN_DOWN = 'taken_down'
}

export interface Coupon {
  id: number
  name: string
  code: string
  times: number
  limitPerUser: number
  usedTimes: number
  discount: number
  usedRatio: number
  startedAt: string
  endedAt: string
  status: CouponStatus
}

export type PartialCoupon = Partial<Coupon>;
