import { Subscription } from './Subscription';
import { User } from './User';
import { Plan } from './Plan';
import { Admin } from './Admin';
import { RefundType } from '@/services/api';
export enum OrderStatus {
  UNPAID = 'unpaid',
  SUCCESS = 'success',
  FAIL = 'fail',
  EXPIRED = 'expired',
  REFUNDED = 'refunded',
  REFUNDING = 'refunding'
}
export enum PaymentGateway {
  GASH = 'gash',
  FUNPOINT = 'funpoint',
  JOYLUCKY = 'joylucky'
}

export enum PaymentMethod {
  CREDIT_CARD = 'credit_card'
}
export interface PaymentFailedDetail {
  code: string
  message: string
}

export interface Refund {
  orderNumber: string
  paymentGateway: PaymentGateway
  transactionId: string
  user: User
  subscription: Subscription
  refundRechargeAmount: number
  paymentMethod: PaymentMethod
  paidAt: string
  plan: Plan
}

export enum OrderType {
  INITIAL = 'initial', // 初始訂單 (新戶
  REBILL ='rebill', // 續費訂單 (續訂
  REFUND_RECHARGE ='refund_recharge', // 重新收費 (補單
  QUICK_VERIFY ='quick_verify' // 快速驗證
}
export interface Order {
  id: number
  memberNo: string
  orderNumber: string
  paymentGateway: string
  transactionId: string
  paymentFailedDetail: PaymentFailedDetail
  purchaseId: string
  user: User
  plan: Plan
  renewalPlan: Plan
  subscription: Subscription
  amount: number
  isRecurring: boolean
  paymentMethod: PaymentMethod
  paidAt: string
  createdAt: string
  status: OrderStatus
  ipAddress: string
  refundRechargeAmount: number
  refundedAt: string
  refundAdmin: Admin
  type: OrderType
  refundOrderNumber: string
  refundType: RefundType
  refundReason: string
  isRefundable: boolean
}

export type PartialOrder = Partial<Order>;
export type PartialRefund = Partial<Refund>;
