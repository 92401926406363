export enum AgentStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled'
}

export interface Agent {
  id: number
  // agent website link
  name: string
  createdAt: string
  updatedAt: string
}

export type PartialAgent = Partial<Agent>;
