import { PartialCoupon } from './Coupon';
import { PartialOrder } from './Order';
import { PartialPlan } from './Plan';
import { PartialUser } from './User';
import { PartialAdmin } from './Admin';

export enum SubscriptionSource {
  PLAN = 'plan',
  COUPON = 'coupon',
  TESTING = 'testing',
  NFT = 'nft'
}
export enum SubscriptionStatus {
  SUBSCRIBING = 'subscribing',
  SUBSCRIBE_CANCEL = 'subscribe_cancel',
  SUBSCRIBE_EXPIRE = 'subscribe_expire'
}
export interface Subscription {
  id: number
  user: PartialUser
  plan: PartialPlan
  coupon: PartialCoupon
  source: SubscriptionSource
  createdAt: string
  startedAt: string
  endedAt: string
  isRenewal: boolean
  order: PartialOrder
  status: SubscriptionStatus
  admin: PartialAdmin
  unsubscribedAt: Date
  isManual: boolean
}

/** @deprecated */
export enum SubscriptionRenew {
  YES = 1,
  NO = 0
}

export type PartialSubscription = Partial<Subscription>;

export interface SubscriptionCancellations {
  id: number
  memberNo: string
  account: string
  subscriptionId: number
  subscriptionPlan: string
  subscriptionStartedAt: string
  cancelReason: string
  canceller: string
  createdAt: string
}
