import { Agent } from './Agent';
import { Video } from './Video';
import { Photo } from './Photo';
import { Topic } from './Topic';
import { Genre } from './Genre';
import { Actor } from './Actor';
import { Director } from './Director';
import { Tag } from './Tag';
import { BaseEntity } from './BaseEntity';
import { MultiLangName } from './MultiLang';

export enum ClassificationOptions {
  AV = 'av',
  DRAMA = 'drama',
  PROGRAM = 'program'
}

export interface Classification {
  id: number
  name: ClassificationOptions
}

export enum SeasonStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled'
}

export interface Season {
  id: number
  introduction: string
  name: string
  number: number
  status: SeasonStatus
  videos: Video[]
}

export enum WorkStatus {
  DRAFT = 'draft',
  REVIEWING = 'reviewing',
  PROCESSING = 'processing',
  PUBLISHED = 'published',
  REJECTED = 'rejected',
  TAKEN_DOWN = 'taken_down',
  EXPIRED = 'expired'
}

export interface Work extends BaseEntity {
  id: number
  name: MultiLangName
  number: string
  isSeries: boolean
  trailer: string
  trailerUrl: string
  trailerSource: string
  trailerViews: number
  noExposeTrailerViews: number
  noExposeTrailer: string
  noExposeTrailerUrl: string
  noExposeTrailerSource: string
  introduction: MultiLangName
  isExclusive: boolean
  status: WorkStatus
  createdAt: string
  publishedAt: string
  agent: Agent
  seasons: Season[]
  classifications: Classification[]
  video: Video
  genres: Genre[]
  actors: Actor[]
  directors: Director[]
  tags: Tag[]
  coverPhoto: Photo
  mobileCoverPhoto: Photo
  titlePhoto: Photo
  captionCoverPhoto: Photo
  topics: Topic[]
  copyrightExpiredAt: string
  noExposeCoverPhoto: Photo
  noExposeMobileCoverPhoto: Photo
  noExposeCaptionCoverPhoto: Photo
  realEvaluation: number
  randomEvaluation: number
  evaluationCount: number
}

export type PartialWork = Partial<Work>;
