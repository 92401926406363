import { StringNumber } from '@/services/api';
import { Channel } from './Channel';

export enum CampaignType {
  CPA = 'cpa',
  CPS = 'cps',
  CPC = 'cpc'
}

export enum UtmFormatType {
  DISPLAY_AD = 'display_ad',
  TEXT = 'text',
  VIDEO = 'video',
  CONTENT = 'content',
  POST = 'post'
}

export interface Campaign {
  id: number
  channel: Channel
  utmMedium: CampaignType
  utmCampaign: string
  utmFormat: UtmFormatType
  utmSize: string
  amount: string
  startedAt: string
  endedAt: string
  initialCommissionPercent: string
  rebillCommissionPercent: string
  actualAmount: string
}

export interface SimpleCampaign {
  date: string
  originalAmount: number
  actualAmount: number
}

export interface CampaignDaily {
  amount: number
  campaigns: Campaign[]
  endedAt: string
  remainingAmount: string
  startedAt: string
}

export type PartialCampaign = Partial<Campaign>;
export type PartialSimpleCampaign = Partial<SimpleCampaign>;
