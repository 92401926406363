import { StringNumber } from '@/services/api';

export enum ChannelAgentWithdrawalStatus {
  AUDITING = 'auditing',
  SUCCESS = 'success',
  FAILED = 'failed'
}

export interface ChannelAgentWithdrawal {
  id: number
  withdrawalNumber: number
  createdAt: string
  channelAgentNumber: string
  receiveBankCode: string
  receiveBankAccount: string
  appliedAmout: StringNumber
  actualAmount: StringNumber
  auditedAt: string
  auditedName: string
  transferedAt: string
  transferName: string
  status: ChannelAgentWithdrawalStatus
}

export type PartialChannelAgentWithdrawal = Partial<ChannelAgentWithdrawal>;
