import { Admin } from './Admin';
import { BaseEntity } from './BaseEntity';
import { Block } from './Block';
import { Photo } from './Photo';

export enum AdvertisementPosition {
  BLOCK_BANNER = 'block_banner',
  PROFILE_BANNER = 'profile_banner',
  FLOAT_BUTTON = 'float_button',
  HEADER_BUTTON = 'header_button'
}

export enum AdvertisementStatus {
  NOT_PUBLISHED = 'not_published',
  PUBLISHED = 'published',
  ENDED = 'ended'
}

export type AdvertisementPositionType = AdvertisementPosition.BLOCK_BANNER | AdvertisementPosition.FLOAT_BUTTON | AdvertisementPosition.HEADER_BUTTON | AdvertisementPosition.PROFILE_BANNER;
export type AdvertisementStatusType = AdvertisementStatus.NOT_PUBLISHED | AdvertisementStatus.PUBLISHED | AdvertisementStatus.ENDED;

export interface Advertisement extends BaseEntity {
  name: string
  link: string
  position: AdvertisementPositionType
  blockIds: number[]
  coverPhotoId: number
  mobileCoverPhotoId: number
  startedAt: string
  endedAt: string
}

export interface Advertisements extends BaseEntity {
  name: string
  link: string
  position: AdvertisementPositionType
  blocks: Block[]
  coverPhotoId: number
  mobileCoverPhotoId: number
  startedAt: string
  endedAt: string
  coverPhoto: Photo
  mobileCoverPhoto: Photo
  status: AdvertisementStatusType
  creator: Admin
  creatorId: number
}

export interface AdvertisementsStatistics extends Advertisements {
  clickHeadcount: string
  impressionHeadcount: string
  clickCount: string
  impressionCount: string
}

export type PartialAdvertisement = Partial<Advertisement>;
