import { StringNumber } from '@/services/api';

/** @deprecated */
export enum PlanInitPeriodUnit {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year'
}
/** @deprecated */
export enum PlanRecurringPeriodUnit {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year'
}
/** @deprecated */
export enum PlanShow {
  YES = 1,
  NO = 0
}
/** @deprecated */
export enum PlanBest {
  YES = 1,
  NO = 0
}

export interface Plan {
  id: number
  name: string
  title: string
  initOriginalPrice: StringNumber
  initDiscountPrice: StringNumber
  isRenewable: boolean
  recurringOriginalPrice: StringNumber
  label: string
  initPeriodCount: StringNumber
  recurringPeriodCount: StringNumber
  averagePrice: StringNumber
  subscribersCount: number

  /** @deprecated */
  init_period_count: number
  /** @deprecated */
  init_original_price: number
  /** @deprecated */
  init_discount_price: number
  /** @deprecated */
  init_period_unit: PlanInitPeriodUnit
  /** @deprecated */
  recurring_period_count: number
  /** @deprecated */
  recurring_period_unit: PlanRecurringPeriodUnit
  /** @deprecated */
  recurring_original_price: number
  /** @deprecated */
  recurring_discount_price: number
  /** @deprecated */
  show: PlanShow
  /** @deprecated */
  is_best: PlanBest
  /** @deprecated */
  sort: number
  /** @deprecated */
  segpay_id: string
  /** @deprecated */
  created_at: string
  /** @deprecated */
  updated_at: string
}

export type PartialPlan = Partial<Plan>;
