import { BaseEntity } from './BaseEntity';
import { Block } from './Block';
import { Photo } from './Photo';
import { MultiLangName } from './MultiLang';
import { Classification, Work } from './Work';

export enum TopicType {
  EXCLUSIVE = 'exclusive',
  BILLBOARD = 'billboard',
  RECOMMEND = 'recommend',
  NEWEST = 'newest',
  WATCH = 'watch',
  FAVORITE = 'favorite',
  CUSTOM = 'custom'
}

export enum TopicStyle {
  MEDIUM = 'medium',
  BILLBOARD = 'billboard',
  IMAGE_TEXT = 'image_text'
}

export interface Topic extends BaseEntity {
  name: MultiLangName
  type: TopicType
  style: TopicStyle
  blocks: Block[]
  sort: number
  topWorkIds: number[]
  classifications: Classification[]
  works: Work[]
  isEnabled: boolean
  isPopular?: boolean
  bannerPhoto: Photo
  borderPhoto: Photo
  thumbnailPhoto: Photo
}

export type PartialTopic = Partial<Topic>;

export interface BlockTopic extends Topic {
  isPopular: boolean
}

export type PartialBlockTopic = Partial<BlockTopic>;
