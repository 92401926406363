import { BaseEntity } from './BaseEntity';
import { Photo } from './Photo';

export enum AnnouncementType {
  SYSTEM = 'system',
  EVENT = 'event'
}

export enum AnnouncementStatus {
  SCHEDULING = 'scheduling',
  PUBLISHED = 'published',
  EXPIRED = 'expired'
}

export enum TargetUserType {
  ALL = 'all',
  NEW = 'new',
  SPECIFIC = 'specific'
}

export interface AnnouncementSpecifiedUser extends BaseEntity {
  memberNo: string
}

export interface Announcement extends BaseEntity {
  content: string
  mobileContent: string
  startedAt: string
  endedAt?: string
  isEnabled: boolean
  isTop: boolean
  targetUsers: TargetUserType
  type: AnnouncementType
  title: string
  notificationPhoto: Photo
  status?: AnnouncementStatus
  /** 發布語系 */
  language: string
}

export type PartialAnnouncement = Partial<Announcement>;
export type CreateAnnouncementData = Omit<PartialAnnouncement, 'notificationPhoto'> & {
  specificMemberNos?: string[]
  notificationPhotoId?: number
};
