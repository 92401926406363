
export interface SuggestionTypeCount{
    product: number
    content: number
    actor: number
}
export interface SuggestionStatusCount{
    verifying: number
    include: number
    exclude: number
}

export enum SuggestionType {
    PRODUCT = 'product',
    CONTENT = 'content',
    ACTOR = 'actor'
}

export enum EventSuggestionStatus {
    VERIFYING = 'verifying',
    VOTING = 'voting',
    FINISHED = 'finished'
}

export enum SuggestionStatus {
    VERIFYING = 'verifying',
    INCLUDE = 'include',
    EXCLUDE = 'exclude'
}
export interface EventsSuggestion {
    id: number
    startedAt: string
    endedAt: string
    suggestionTypeCount: SuggestionTypeCount
    suggestionStatusCount: SuggestionStatusCount
    status: EventSuggestionStatus
    createdAt: string
    updatedAt: string
}

export interface Suggestions {
    id: number
    memberNo: string
    type: SuggestionType
    content: string
    status: SuggestionStatus
    createdAt: string
    updatedAt: string
    voteCounts: number
    rank: number
    typeVoteCounts: number
    wteEventId: number
    voteLastTime: string
}

export type PartialEventSuggestion = Partial<EventsSuggestion>;
export type PartialSuggestionTypeCount = Partial<SuggestionTypeCount>;
export type PartialSuggestions = Partial<Suggestions>;
