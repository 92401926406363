
export enum RegisterMethod {
    ORGANIC = 'organic',
    GOOGLE = 'google'
}

export enum VerifiedStatus {
    VERIFIED = 'verified',
    VERIFYING = 'verifying',
    NOT_VERIFIED = 'not_verified',
    FAILED = 'failed'
}

export enum VerifiedType {
    ID_CARD = 'id_card',
    IC_CARD = 'ic_card',
    CREDIT_CARD = 'credit_card',
    ADMIN_USER = 'admin_user',
    NFT = 'nft',
    TWID = 'twid'
}

export type VerifiedStatusType = VerifiedStatus.VERIFIED | VerifiedStatus.VERIFYING | VerifiedStatus.NOT_VERIFIED | VerifiedStatus.FAILED

export interface Review {
    id: number
    submissionedAt: string
    reviewedAt: string
    memberNo: string
    username: string
    verifiedStatus: VerifiedStatusType
    verifiedType: VerifiedType
    idPhotoPath: string
    isManual: boolean
}

export type PartialReview = Partial<Review>;
