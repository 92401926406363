import { Photo } from './Photo';

export enum VideoStatus {
  DRAFT = 'draft',
  REVIEWING = 'reviewing',
  PROCESSING = 'processing',
  PUBLISHED = 'published',
  REJECTED = 'rejected',
  EXPIRED = 'expired',
  TAKEN_DOWN = 'taken_down'
}

export interface Video {
  id: number
  name: string
  durationInSecond: number
  introduction: string
  trailer: string
  trailerUrl: string
  trailerSource: string
  trailerViews: number
  video: string
  videoUrl: string
  videoSource: string
  views: number
  episode: number
  status: VideoStatus
  publishedAt: string
  createdAt: string
  coverPhoto: Photo
  seasonId: number
}

export type PartialVideo = Partial<Video>;
