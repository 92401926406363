export enum ShowExposesStatus {
    PREPARED = 'prepared',
    ACTIVED = 'actived',
    EXPIRED = 'expired'
}

export interface ShowExposes {
  id: number
  startDate: string
  endDate: string
  startTime: string
  endTime: string
  status: ShowExposesStatus
  enable: Boolean
  updatedAt: string
}

export interface ShowExposesForm {
  dateArr: string[] | string
  startTime: string
  endTime: string
  enable: Boolean
}

export type PartialShowExposes = Partial<ShowExposes>
