import { Campaign } from './Campaign';
import { ChannelAgent } from './ChannelAgent';

/** @deprecated */
export enum ChannelType {
  CPA = 'cpa',
  CPS = 'cps',
  CPC = 'cpc',
  CPT = 'cpt',
  CPD = 'cpd'
}
export enum Promotion {
  CHANNEL = '渠道',
  MARKETING = '行銷',
  OPERATING= '營運'
}
export interface Channel {
  id: number
  createdAt: string
  utmSource: string
  note: string
  creator: string
  campaignCount: number
  campaigns: Campaign
  channelAgent: ChannelAgent
  channelAgentId: number
  promotion: Promotion
  /** @deprecated */
  type: ChannelType
  /** @deprecated */
  utm_source: string
  /** @deprecated */
  created_at: string
  /** @deprecated */
  updated_at: string
  /** @deprecated */
  campaigns_count: number
  /** @deprecated */
  admins_count: number
}

export type PartialChannel = Partial<Channel>;
