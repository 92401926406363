import { VerifiedType, VerifiedStatus } from '@/interfaces';

export const setVerifiedStatus = (verifiedStatus: VerifiedStatus) => {
  const value = {
    text: '',
    tagColor: ''
  };
  switch (verifiedStatus) {
    case 'verified':
      value.text = '已驗證';
      value.tagColor = 'success';
      return value;
    case 'verifying':
      value.text = '驗證中';
      value.tagColor = 'info';
      return value;
    case 'not_verified':
      value.text = '尚未驗證';
      value.tagColor = 'warning';
      return value;
    case 'failed':
      value.text = '驗證失敗';
      value.tagColor = 'danger';
      return value;
    default:
      break;
  }
};

export const setVerifiedType = (verifiedType: VerifiedType) => {
  switch (verifiedType) {
    case VerifiedType.ID_CARD:
      return '身份證';
    case VerifiedType.IC_CARD:
      return '健保卡';
    case VerifiedType.CREDIT_CARD:
      return '信用卡';
    case VerifiedType.ADMIN_USER:
      return '後台新增';
    case VerifiedType.NFT:
      return '開通NFT會員';
    case VerifiedType.TWID:
      return 'TWID';
    default:
      return '';
  }
};
