import { PartialCoupon } from './Coupon';
import { Photo } from './Photo';
import { Review } from './Review';
import { Subscription } from './Subscription';

export enum EventsStatus {
  SCHEDULED = 'scheduled',
  PUBLISHED = 'published',
  REGISTRATION_CLOSED = 'registration_closed',
  CLOSED = 'closed'
}

export type EventsStatusType = EventsStatus.SCHEDULED | EventsStatus.PUBLISHED | EventsStatus.REGISTRATION_CLOSED | EventsStatus.CLOSED

export enum ELinkType {
  NONE = 'none',
  ENROLL = 'enroll',
  REDIRECT = 'redirect',
}

export type TLinkType = ELinkType.NONE | ELinkType.ENROLL | ELinkType.REDIRECT;

export interface EventSection {
  mobilePhoto: Photo
  photo: Photo
  photoId: number
  mobilePhotoId: number
  name: string
  linkType: TLinkType
  linkUrl: string
  id: number
  eventId: number
  sort: number
}

export interface Event {
  id: number
  key: string
  name: string
  startedAt: string
  endedAt: string
  enrollEndedAt: string
  status: EventsStatusType
  seoTitle: string
  seoDescription: string
  seoKeywords: string
  emailNotification: string
  pageViews: number
  enrollmentCount: number
  eventSections: EventSection[]
}

export type PartialEvent = Partial<Event>;

export interface EventsStatistics {
  date: string
  pageViews: number
  enrollmentCount: number
  clickCount: number[]
}

export interface EventsEnrollments {
  id: number
  createdAt: string
  memberNo: string
  account: string
  contactMail: string
  registeredAt: string
  subscriptionPlan: string
  subscriptionPeriod: string
  verifiedStatus: string
  couponCode: string
  isNFTMember: boolean
  lastLoginTime: string
  isBlack: boolean
  realName: string
  countryCode: string
  phone: string
  address: string
}
