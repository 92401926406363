
import { defineComponent, type Ref, ref, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessageBox, ElMessage } from 'element-plus';
import download from 'downloadjs';
import { invert } from 'lodash';
import dayjs from 'dayjs';
import { formatLocalTime, formatISOString } from '@/utils/format-time';
import {
  useUsers,
  useDeleteUser
} from '@/composables/api';
import {
  DEFAULT_PAGE_SIZE,
  DELETED_SUCCESSFULLY_TEXT,
  exportUsers,
  ExportUsersRequestOptions,
  IntBoolean,
  ResponseError,
  UserIdOption,
  UserSortKey
} from '@/services/api';
import { VerifiedStatus, RegisterMethod, User } from '@/interfaces';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { setVerifiedStatus } from './utils';
import { useKeyword, usePage, useSort } from '@/composables';
import SectionLayout from '@/components/SectionLayout.vue';
import { SORT_MAP } from '@/views/constants';

const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.SELECTOR,
    label: '註冊方式',
    placeholder: '請選擇',
    options: [
      {
        label: '一般註冊',
        value: `${RegisterMethod.ORGANIC}`
      },
      {
        label: 'Google',
        value: `${RegisterMethod.GOOGLE}`
      }
    ]
  },
  {
    type: FilterType.SELECTOR,
    label: '年齡驗證狀態',
    placeholder: '請選擇',
    options: [
      {
        label: '已驗證',
        value: `${VerifiedStatus.VERIFIED}`
      },
      {
        label: '驗證中',
        value: `${VerifiedStatus.VERIFYING}`
      },
      {
        label: '尚未驗證',
        value: `${VerifiedStatus.NOT_VERIFIED}`
      },
      {
        label: '驗證失敗',
        value: `${VerifiedStatus.FAILED}`
      }
    ]
  },
  {
    type: FilterType.SELECTOR,
    label: '電子信箱是否已驗證',
    placeholder: '請選擇',
    options: [
      {
        label: '是',
        value: `${IntBoolean.TRUE}`
      },
      {
        label: '否',
        value: `${IntBoolean.FALSE}`
      }
    ]
  },
  {
    type: FilterType.SELECTOR,
    label: '是否為 NFT 會員',
    placeholder: '請選擇',
    options: [
      {
        label: '是',
        value: `${IntBoolean.TRUE}`
      },
      {
        label: '否',
        value: `${IntBoolean.FALSE}`
      }
    ]
  },
  {
    type: FilterType.SELECTOR,
    label: '是否為黑名單',
    placeholder: '請選擇',
    options: [
      {
        label: '是',
        value: `${IntBoolean.TRUE}`
      },
      {
        label: '否',
        value: `${IntBoolean.FALSE}`
      }
    ]
  }
];

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits,
    SectionLayout
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);

    const isNFTMember = ref();
    const isBlack = ref();
    const isContactMailVerified = ref();
    const registerMethod = ref();
    const verifiedStatus = ref();
    const registerDateRange = ref(null);
    const registerStartAt = ref('');
    const registerEndAt = ref('');
    const statusIndex = ref(undefined);
    const { sortOrder, sortKey } = useSort<UserSortKey>();
    const { data, isLoading, isFetching, refetch } = useUsers({
      page,
      keyword,
      isNFTMember,
      isBlack,
      isContactMailVerified,
      verifiedStatus,
      registerMethod,
      registerStartAt,
      registerEndAt,
      pageSize,
      sort: sortOrder,
      sortKey
    });
    const { isLoading: isDeleting, mutate } = useDeleteUser();

    const handleSortChange = ({ prop, order }: {
      prop: UserSortKey
      order: 'ascending' | 'descending'
      }) => {
      sortKey.value = prop || undefined;
      sortOrder.value = SORT_MAP[order];
    };

    const handleFilterChange = (event: FilterEvent) => {
      registerMethod.value = event[0];
      verifiedStatus.value = event[1];
      isContactMailVerified.value = event[2];
      isNFTMember.value = event[3];
      isBlack.value = event[4];
    };

    const handleDateChange = () => {
      if (!registerDateRange.value) {
        registerStartAt.value = '';
        registerEndAt.value = '';

        return;
      }
      registerStartAt.value = formatISOString(registerDateRange.value[0]);
      registerEndAt.value = formatISOString(registerDateRange.value[1]);
    };

    const deleteUser = ({ userId }: UserIdOption, index: Number) => {
      statusIndex.value = index;
      ElMessageBox.confirm('是否確認要刪除？', '警告', {
        confirmButtonText: '刪除',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          mutate(
            { userId },
            {
              onSuccess() {
                refetch.value();

                ElMessage.success(DELETED_SUCCESSFULLY_TEXT);
              },
              onError(error: ResponseError) {
                ElMessage({
                  message: error.response?.data.message,
                  type: 'error'
                });
              }
            }
          );
        })
        .catch();
    };

    // export dialog
    const showExportDialog = ref(false);

    const userBasic = ref({
      name: '會員基本資料',
      titleCheckbox: false,
      subCheckbox: [
        { key: 'id', name: '項次', checked: false },
        { key: 'createdAt', name: '註冊時間', checked: false },
        { key: 'memberNo', name: '會員編號', checked: false },
        { key: 'username', name: '暱稱', checked: false },
        { key: 'birthday', name: '生日', checked: false },
        { key: 'registerMethod', name: '註冊來源', checked: false },
        { key: 'account', name: '會員帳號', checked: false },
        { key: 'contactMail', name: '電子信箱', checked: false },
        { key: 'oauthEmail', name: '三方信箱', checked: false },
        { key: 'lastLoginTime', name: '最後登入時間', checked: false },
        { key: 'lastLoginIp', name: '最後登入IP', checked: false },
        { key: 'realName', name: '姓名', checked: false },
        { key: 'phone', name: '電話', checked: false },
        { key: 'address', name: '地址', checked: false }
      ]
    });

    const others = ref({
      name: '其他資料',
      titleCheckbox: false,
      subCheckbox: [
        { key: 'comment', name: '備註內容', checked: false },
        { key: 'ipNation', name: '地區(IP)', checked: false },
        { key: 'language', name: '語系', checked: false },
        { key: 'isBlack', name: '黑名單', checked: false }
      ]
    });

    const ageValidate = ref({
      name: '年齡驗證',
      titleCheckbox: false,
      subCheckbox: [
        { key: 'verifyStatus', name: '驗證狀態', checked: false },
        { key: 'verifyMethod', name: '驗證方式', checked: false }
      ]
    });

    const subscribe = ref({
      name: '訂閱資料',
      titleCheckbox: false,
      subCheckbox: [
        { key: 'subscriptionPlan', name: '目前訂閱方案', checked: false },
        { key: 'subscriptionDuration', name: '訂閱期間', checked: false }
      ]
    });

    const nft = ref({
      name: 'NFT相關資訊',
      titleCheckbox: false,
      subCheckbox: [
        { key: 'isNftMember', name: 'NFT會員', checked: false },
        { key: 'walletAddress', name: '錢包地址', checked: false }
      ]
    });

    const wte = ref({
      name: 'WTE活動數據',
      titleCheckbox: false,
      subCheckbox: [
        { key: 'wteEventId', name: '活動ID', checked: false },
        { key: 'wteEventDuration', name: '活動走期', checked: false },
        { key: 'wteEventSuggestions', name: '建議數', checked: false },
        { key: 'wteEventSuggestionIncludes', name: '入選建議數', checked: false },
        { key: 'wteEventSuggestionBingos', name: '獲獎建議數', checked: false },
        { key: 'wteEventProfits', name: '總分潤％', checked: false },
        { key: 'wteEventNft', name: 'NFT持有量', checked: false },
        { key: 'wteEventWithdrawalAppliedAmount', name: 'ETH/USDT兌匯', checked: false },
        { key: 'wteEventWithdrawalReceivedAmount', name: '實際派發USDT', checked: false }
      ]
    });

    const turnAllFalse = (refObj, val: boolean) => {
      refObj.value.subCheckbox.forEach((item) => {
        item.checked = val;
      });
    };

    const turnOffTitleCheckbox = (subCheckbox) => {
      const allFalse = subCheckbox.every(item => !item.checked);
      if (allFalse && userBasic.value.titleCheckbox) userBasic.value.titleCheckbox = false;
    };

    watch(() => userBasic.value.titleCheckbox, (newVal) => {
      turnAllFalse(userBasic, newVal);
    }, { deep: true });

    watch(() => userBasic.value.subCheckbox, (newVal) => {
      turnOffTitleCheckbox(newVal);
    }, { deep: true });

    watch(() => others.value.titleCheckbox, (newVal) => {
      turnAllFalse(others, newVal);
    }, { deep: true });

    watch(() => others.value.subCheckbox, (newVal) => {
      turnOffTitleCheckbox(newVal);
    }, { deep: true });

    watch(() => ageValidate.value.titleCheckbox, (newVal) => {
      turnAllFalse(ageValidate, newVal);
    }, { deep: true });

    watch(() => ageValidate.value.subCheckbox, (newVal) => {
      turnOffTitleCheckbox(newVal);
    }, { deep: true });

    watch(() => subscribe.value.titleCheckbox, (newVal) => {
      turnAllFalse(subscribe, newVal);
    }, { deep: true });

    watch(() => subscribe.value.subCheckbox, (newVal) => {
      turnOffTitleCheckbox(newVal);
    }, { deep: true });

    watch(() => nft.value.titleCheckbox, (newVal) => {
      turnAllFalse(nft, newVal);
    }, { deep: true });

    watch(() => nft.value.subCheckbox, (newVal) => {
      turnOffTitleCheckbox(newVal);
    }, { deep: true });

    watch(() => wte.value.titleCheckbox, (newVal) => {
      turnAllFalse(wte, newVal);
    }, { deep: true });

    watch(() => wte.value.subCheckbox, (newVal) => {
      turnOffTitleCheckbox(newVal);
    }, { deep: true });

    const getCheckedKeys = () => {
      return [
        ...userBasic.value.subCheckbox.reduce((acc, item) => (item.checked) ? [...acc, item.key] : acc, []),
        ...others.value.subCheckbox.reduce((acc, item) => (item.checked) ? [...acc, item.key] : acc, []),
        ...ageValidate.value.subCheckbox.reduce((acc, item) => (item.checked) ? [...acc, item.key] : acc, []),
        ...subscribe.value.subCheckbox.reduce((acc, item) => (item.checked) ? [...acc, item.key] : acc, []),
        ...nft.value.subCheckbox.reduce((acc, item) => (item.checked) ? [...acc, item.key] : acc, []),
        ...wte.value.subCheckbox.reduce((acc, item) => (item.checked) ? [...acc, item.key] : acc, [])
      ];
    };

    const handleExport = async() => {
      const params: ExportUsersRequestOptions = {
        query: {
          keyword: keyword.value,
          isNFTMember: isNFTMember.value,
          registerMethod: registerMethod.value,
          verifiedStatus: verifiedStatus.value,
          sort: sortOrder.value,
          sortKey: sortKey.value,
          registerStartAt: registerStartAt.value,
          registerEndAt: registerEndAt.value,
          exportColumns: getCheckedKeys()
        }
      };
      try {
        const data = await exportUsers(params);
        const currentDate = dayjs().format('YYYY-MM-DD');

        download(data, `${currentDate}會員列表.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      } catch (error: any) {
        ElMessage.error(error.response?.data.message);
      }
      showExportDialog.value = false;
    };

    // cache搜索條件在querystring
    watch([registerStartAt, registerEndAt], ([registerStartAt, registerEndAt]) => {
      router.push({ query: { ...route.query, registerStartAt, registerEndAt } });
    });
    // 從querystring帶入搜索條件
    watch(
      () => route.query,
      (query) => {
        registerStartAt.value = query.registerStartAt as string;
        registerEndAt.value = query.registerEndAt as string;

        if (registerStartAt.value && registerEndAt.value) {
          registerDateRange.value = [registerStartAt.value, registerEndAt.value];
          return;
        }
        registerDateRange.value = null;
      },
      { immediate: true }
    );

    return {
      page,
      data,
      isLoading,
      isDeleting,
      isFetching,
      statusIndex,
      FILTER_OPTIONS,
      registerDateRange,
      keyword,
      deleteUser,
      handleSortChange,
      formatLocalTime,
      handleDateChange,
      handleFilterChange,
      setVerifiedStatus,
      setPageQuery,
      showExportDialog,
      userBasic,
      others,
      ageValidate,
      subscribe,
      nft,
      wte,
      handleExport,
      defaultSort: computed(() => ({
        prop: sortKey.value,
        order: invert(SORT_MAP)[sortOrder.value]
      }))
    };
  }
});
