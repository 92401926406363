
import { defineComponent, ref } from 'vue';

export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: null
    },
    clickSearch: {
      type: Function,
      required: true
    },
    inputWidth: {
      type: String,
      default: '230px'
    },
    clearable: {
      type: Boolean,
      default: false
    },
    keyword: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const keywordValue = ref(props.keyword);

    const searchKeyword = () => {
      props.clickSearch(keywordValue.value.trim());
    };

    return {
      keywordValue,
      searchKeyword
    };
  }

});
